import React from 'react';
import { Helmet } from 'react-helmet';

export default function SEO({ 
  title, 
  description,
}) {

  return (
    <Helmet>

      {/* Standard Metadata */}
      <title>{title}</title>
      <meta name='description' content={description} />

      {/* Facebook */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      {/* Twitter */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

    </Helmet>
  );

}